import aliasAllocationMethodsReducer from './aliasAllocationMethods';
import authenticationReducer from './authentication';
import errorReducer from './error';
import integrationsReducer from './integrations';
import languageReducer from './language';
import loaderReducer from './loader';
import nodesReducer from './nodes';
import propertiesReducer from './properties';
import sortingDegreesReducer from './sortingDegrees';
import stationsReducer from './stations';
import stationAliasBranchesReducer from './stationAliasBranches';
import stationReservedAliasesReducer from './stationReservedAliases';
import stationAliasLetterLimitsReducer from './stationAliasLetterLimits';
import stationIntegrationsReducer from './stationIntegrations';
import stationNetworkLayoutReducer from './stationNetworkLayout';
import stationPropertyValuesReducer from './stationPropertyValues';
import stationAllPropertyValuesReducer from './stationAllPropertyValues';
import stationReturnPropertyValuesReducer from './stationReturnPropertyValues';
import stationReturnConfigsReducer from './stationReturnConfigs';
import stationReturnAliasGroupOptionsReducer from './stationReturnAliasGroupOptions';
import stationReturnAliasGroupsReducer from './stationReturnAliasGroups';
import stationSortingConfigsReducer from './stationSortingConfigs';
import stationUsersReducer from './stationUsers';
import stationApiUsersReducer from './stationApiUsers';
import stationAliasGroupsReducer from './stationAliasGroups';
import systemInfoReducer from './systemInfo';
import userInfoReducer from './userInfo';

export {
  aliasAllocationMethodsReducer,
  authenticationReducer,
  errorReducer,
  integrationsReducer,
  languageReducer,
  loaderReducer,
  nodesReducer,
  propertiesReducer,
  stationPropertyValuesReducer,
  stationReturnPropertyValuesReducer,
  stationAllPropertyValuesReducer,
  stationAliasBranchesReducer,
  stationReservedAliasesReducer,
  stationAliasGroupsReducer,
  stationReturnAliasGroupsReducer,
  stationReturnAliasGroupOptionsReducer,
  stationAliasLetterLimitsReducer,
  sortingDegreesReducer,
  stationSortingConfigsReducer,
  stationIntegrationsReducer,
  stationNetworkLayoutReducer,
  stationReturnConfigsReducer,
  stationsReducer,
  stationUsersReducer,
  stationApiUsersReducer,
  systemInfoReducer,
  userInfoReducer,
};
